import React from "react";
import Smile from '../images/smile.png';
import Team from '../images/team.png';
import Ribbon from '../images/bow.png';
import Celebration from '../images/celebration.png'

const Section2 = () => {
    return (
        <div className="container-fluid mt-5 section2 p-5" id="aboutUs">
            <div className="row d-flex justify-content-center mb-5">
                <div className="col-md-8">
                    <div className="section2_heading2 p-2   ">
                        <h1 className="section2-heading">India's one of Best</h1>
                        <h1 className="section2-heading"> <span style={{ color: '#3C25C9' }}>Advertisement</span> Agency</h1>
                    </div>
                </div>
                <div className="col-md-4 section2_heading1">
                    <h3>For instant Rates contact with our ad booking representative :<span style={{ color: "red" }}>9408170571</span></h3>

                </div>
            </div>
            <div className="row d-flex justify-content-center">
                <div className="col-sm-12 col-lg-10 section2_icon_div p-4">
                <p className="section2_p">
                    Discover unparalleled reach with India's premier paper insertion agency, seamlessly blending tradition with innovative strategies to catapult your brand into the spotlight. Renowned for our precision-targeted approach, we ensure your advertisement lands directly in the hands of your intended audience, sparking engagement and driving results. Embrace the future of impactful advertising with us, where every insert is a step towards achieving your marketing zenith.
                    Join the ranks of our satisfied clientele and experience the pinnacle of paper insertion excellence.
                </p>
                    <div className="row d-flex justify-content-center">
                        <div className="col-sm-2 col-mb-3 col-lg-2 m-3 justify-content-center  section2-review1">
                            <img src={Smile} width="50%" alt="smile"/>
                            <h3 className="animated-text">150+</h3>
                            <p>Happy Customers</p>
                        </div>
                        <div className="col-sm-2 col-mb-3 col-lg-2 m-3  section2-review">
                        <img src={Team} width="50%" alt="team"/>
                            <h3 className="animated-text">8+</h3>
                            <p>Experienced people serving to clients</p>
                        </div>
                        <div className="col-sm-2 col-mb-3 col-lg-2 m-3  section2-review">
                        <img src={Celebration} width="50%" alt="celebration"/>
                            <h3 className="animated-text">4.5+</h3>
                            <p>Business and digital marketing</p>
                        </div>
                        <div className="col-sm-2 col-mb-3 col-lg-2 m-3  section2-review">
                        <img src={Ribbon} width="50%" alt="ribbon"/>
                            <h3 className="animated-text">20+</h3>
                            <p>TiedUp with clients</p>
                        </div>
                    </div>
                </div>

            </div>
           
        </div>
    )
}

export default Section2