import React, { useEffect } from 'react';
import Home from './home';
import HowBooked from './HowToBooked';
import Section1 from './section1';
import Section2 from './section2';
import Section3 from './section3';
import Section5 from './section5';
import Section6 from './section6';
import Section7 from './section7';
import Inquiry from './inquiry';
import Whypaperinsertion from './whyPaperInsertions'
import Topchoice from './topChoise'

const Mainhome = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const scrollTo = urlParams.get('page');
    if (scrollTo) {
      const targetSection = document.getElementById(scrollTo);
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  });

  return (
    <>
      <Home />
      <div className='container'>
        <Whypaperinsertion />
        <HowBooked />
        <Topchoice/>
        <Section1 />
        <Section2 />
        <Section3 />
        <Section5 />
        <Section6 />
        <Inquiry />
        <Section7 />
      </div>
    </>
  );
};

export default Mainhome;
