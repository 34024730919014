import React from "react";

const Refund = () => {
    return (
        <>
            <div className="container-fluid privacy_Image" >
                <h1 className="pt-5">Terms and Conditions</h1>
            </div>

            <div className="container mt-5 mb-5">
                <h4 className="page_h4">Policy</h4>

                <p className="p2">We appreciate the trust you place in us when you visit our site. We have underlined the privacy policy of our site below. When you browse our site, you agree to the below terms.</p>

                <ul className="ul1">
                    <li className="li3"><span className="s2">Our vision is to make it easy for advertisers to discover the tabloid advertising spaces available in Ahmedabad visibility ventures. In that regard, we list various advertising spaces details on our site. Most of these are Next Edition. Sometimes we also list some Success Story publicly available information on Our site with the objective of helping them to Discover business and its self &amp; generate more sales from the thousands of advertisers visiting our page. We have noble intentions and would love to work with partners who understand the Tabloid ads and its Reach towards Ahmedabad..</span></li>
                    <li className="li3"><span className="s2">We use Google Analytics to get aggregate data that helps us with various data points that help us take business decisions. The kind of data we get is aggregate and not individual. The data collected gives us an understanding of how many people visited our site, how many of them were unique, the number of page views, time spent, location of the users, the pages they viewed, the browser used etc.</span></li>
                    <li className="li3"><span className="s2">Some users subscribe to our e-mail newsletters. Here we ask them to share their e-mail IDs with us. This information is not shared with anybody and we only send new media / new notifications to these users. We do not spam our users with daily or weekly alerts. We only mail them when something worth their while is to be reported. We don't sell or share your personal contact details with anybody, for commercial or other purposes. Your data shared with Ahmedabad visibility ventures is confidential with us.</span></li>
                    <li className="li3"><span className="s2"><b>There has been no any refund and cancellation on booking for next edition advertisement.</b></span></li>
                    <li className="li3"><span className="s2">All the rights reserved with Ahmedabad visibility ventures.</span></li>
                    <li className="li3"><span className="s2">Request to do not copy any content and images from this sites.</span></li>
                    <li className="li3"><span className="s2">We use cookies on our site. A cookie is a small piece of information stored by a web server on a web browser so it can be later read back from that browser. Cookies are useful for enabling the browser to remember information specific to a given user. We place both permanent and temporary cookies in your computer's hard drive. The cookies do not contain any of your personally identifiable information. These cookies are safe and help you more than they help us by making your browsing experience more fruitful. These are not dangerous and don't harm you or your computer.</span></li>
                </ul>

                <h4 className="page_h4">IP Infringement</h4>

                <p className="p2">If you have a good faith belief that your IP right has been violated or information is misrepresented on Ahmedabad visibility ventures, please report it to admin@ahmedabadvisibilityventures.com. We would need 3 working days to address the issue To help us solve the issue fast please include the following in your email</p>

                <ul className="ul1">
                    <li className="li3"><span className="s2">Identification or description of the copyrighted work/ trademark that has been infringed</span></li>
                    <li className="li3"><span className="s2">Data that is being wrongly represented</span></li>
                    <li className="li3"><span className="s2">Change(s) that you would want us to make</span></li>
                    <li className="li3"><span className="s2">A name & number to contact</span></li>
                </ul>
            </div>
        </>
    );
}

export default Refund;
