import React from "react";

const Section6 = () => {
  
    return (
        <>
            <div className="container pb-5" id="advantages">
                <div className="row justify-content-center">
                    <div className="col-12 text-center">
                        <h1 className="headings">ADVANTAGES OF NEWSPAPER TABLOID ADVERTISING</h1>
                    </div>
                    <div className="col-12 text-center mt-4">
                        <p style={{ margin: '0 20px' }}>
                            Newspaper insertion advertising offers a unique blend of benefits, making it a standout choice for marketers aiming to make an impactful statement:
                        </p>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-md-5 section6-content mx-3 mt-2">
                        <h4 className="subheading">1.Targeted Reach:</h4>
                        <p className="text-muted">Tailor your message to specific demographics or geographic areas, ensuring your advertisement lands directly in the hands of your target audience.</p>
                    </div>
                    <div className="col-md-5 section6-content1 mt-2">
                        <h4 className="subheading">2.High Engagement:</h4>
                        <p className="text-muted">
                            Benefit from the tactile nature of physical ads, which often receive more thorough attention compared to digital counterparts, enhancing message retention.
                        </p>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-md-5 section6-content1 mx-3 mt-2">
                        <h4 className="subheading">3.Cost-Effectiveness:</h4>
                        <p className="text-muted">
                            Compared to traditional newspaper ads, inserts often provide a more affordable option with the added advantage of bulk distribution deals.
                        </p>
                    </div>
                    <div className="col-md-5 section6-content mt-2">
                        <h4 className="subheading">4.Flexibility:</h4>
                        <p className="text-muted">From design to format, customize every aspect of your insert to match your campaign needs, whether it's a flyer, brochure, or coupon.</p>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-md-5 section6-content mx-3 mt-2">
                        <h4 className="subheading">5.Enhanced Credibility:</h4>
                        <p className="text-muted">Leveraging the established trust in newspaper media to bolster your brand's reliability and reputation among consumers.</p>
                    </div>
                    <div className="col-md-5 section6-content1 mt-2">
                        <h4 className="subheading">6.Extended Lifespan:</h4>
                        <p className="text-muted">Unlike digital ads that can be easily overlooked or closed, newspaper inserts can be saved, shared, or revisited, extending their reach and influence over time.</p>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Section6
