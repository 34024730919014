import React from "react";


const Section3 = () => {
    return (
        <div className="container mt-5">
    <div className="row justify-content-center text-center">
        <div className="col-lg-5 col-md-5 col-sm-12 m-2 section3">
            <div className="section3_content">
                <h4 className="section3_headings mb-3">Newspaper Tabloid Ads Design Services in India</h4>
                <p className="pb-5">You want people to notice your business. You can greatly benefit from creating newspaper tabloid ads. Newspaper tabloid ads are effective at expanding audiences and attracting new clients. Make a noticeable advertisement with an eye-catching design. Utilize <b>our customer newspaper tabloid ads design & magazine ads Design service to stand out from the crowd.</b></p>
            </div>
        </div>
        <div className="col-lg-5 col-md-5 col-sm-12 m-2 section3">
            <div className="section3_content">
                <h4 className="section3_headings mb-3">DESIGN A NEWSPAPER TABLOID AD FOR YOUR BUSINESS PROMOTION</h4>
                <p className="pb-5">A newspaper tabloid advertisement can be one of the most effective ways to reach millions of people. Place an ad that people are likely to read to ensure that the money you spend on ads is worthwhile.<b>Professional designer to create paper and designs</b> to help your business.</p>
            </div>
        </div>
    </div>
</div>

    )
}

export default Section3