import React from "react";
import { TfiLayoutListThumb, TfiLayoutGrid4 } from "react-icons/tfi";
import Payment from '../images/payment.png';
import Page from '../images/page1.png';
import Select1Image from '../images/select_1.png';
import Select2Image from '../images/select_2.png';
import Select3Image from '../images/select_3.png';



const HowBooked = () => {
    return (

        <div className="container mt-5 how_to_ads_book p-5" id="how_to_ads_book">
        <h1 className="headings text-center">How to Book Ads?</h1>
        <p className="text-center">&#8594; 3 easy steps.</p>
        <div className="row justify-content-center mt-5">
            <div className="col-lg-3 col-md-6 col-sm-6 col-12 text-center">
                <img src={Select1Image} className="select_1_image" alt="select_1_image" />
            </div>
            <div className="col-12 d-lg-none arrow text-center">&#8595;</div>
            <div className="col-lg-1 col-md-1 col-12 arrow d-none d-lg-block text-center">&#8594;</div>
    
            <div className="col-lg-3 col-md-6 col-sm-6 col-12 text-center">
                <img src={Select2Image} className="select_2_image"  alt="select_2_image" />
            </div>
            <div className="col-12 d-lg-none arrow text-center">&#8595;</div>
            <div className="col-lg-1 col-md-1 col-12 arrow d-none d-lg-block text-center">&#8594;</div>
    
            <div className="col-lg-3 col-md-6 col-sm-6 col-12 text-center">
                <img src={Select3Image} className="select_3_image"  alt="select_3_image" />
            </div>
        </div>
    </div>
    

    )
}

export default HowBooked