import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from 'moment';
const baseurl = process.env.REACT_APP_BASE_URL
const slotEndpoint = process.env.REACT_APP_SLOT_LIST_ENDPOINT;


const Slot = () => {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handlePageOpen = (date, _id) => {
        navigate("/select-page", { state: { day: date, slotId: _id } });
    };
    const [slots, setSlots] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(`${baseurl}/${slotEndpoint}`, { cityId: 1 });
                setSlots(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);
    const formatDate = (dateString) => {
        return moment(dateString).format('DD-MM-YYYY');
    }

    return (
        <>
            <div className="container-fluid slot" style={{ paddingTop: '10rem', paddingBottom: '8rem' }}>
                <div className="row d-flex justify-content-center">

                    <div className="col-6 slot-dates p-5">
                        <div className="row d-flex justify-content-center">
                            <h1 className="headings slot-head mb-5">Select date</h1>

                            {slots.map((slot, index) => (
                                slot.status ? (
                                    <div
                                        key={index}
                                        className={`col-sm-6 col-md-6 col-lg-4 m-2 slotBox`}
                                        onClick={() => handlePageOpen(slot.date, slot._id)}
                                    >
                                        {formatDate(slot.date)}
                                    </div>
                                ) : null
                            ))}
                        </div>
                    </div>
                </div>

            </div>
            <div className="container">
                <div className="row d-flex justify-content-center slot-footer">
                    <h1>AHMEDABAD</h1>
                    <h1>VISIBILITY VENTURE</h1>
                </div>
            </div>
        </>
    )
};

export default Slot;
