import React from "react";

const Privacy = () => {
    return (
        <>
            <div className="container-fluid privacy_Image" >
               <h1 className="pt-5"> PRIVACY POLICY</h1>
            </div>


            <div className="container">
                <div>
                    <h5>We appreciate the trust you place in us when you visit our site. We have underlined the privacy policy of our site below. When you browse our site, you agree to the below terms.</h5>
                </div>
                <div className="privacy">
                    <ul>
                        <li>We don’t collect any personally identifiable information of our users.</li>
                        <li>We use Google Analytics to get aggregate data that helps us with various data points that help us take business decisions. The kind of data we get is aggregate and not individual. The data collected gives us an understanding of how many people visited our site, how many of them were unique, the number of pageviews, time spent, location of the users, the pages they viewed, the browser used etc.</li>
                        <li>At times we use in-page analytics tools such as clicktale, that helps us understand individual browsing behavior. Again, this data is not personally identifiable since we don't ask users to give us any of their personal data on our site. We also use some chat applets from time-to-time to help our users with any queries they may have, real-time.</li>
                        <li>Some users subscribe to our e-mail newsletters. Here we ask them to share their e-mail IDs with us. This information is not shared with anybody and we only send new media / new notifications to these users. We do not spam our users with daily or weekly alerts. We only mail them when something worth their while is to be reported. We don't sell or share your personal contact details with anybody, for commercial or other purposes. Your data shared with The Media Ant is confidential with us.</li>
                        <li>Our vision is to make it easy for advertisers to discover the sea of tabloid advertising spaces available in Ahmedabad visibility ventures If you're a media owner whose media has been listed and you'd like us to discontinue showing it on our site, please write in to admin@ahmedabadvisibilityventures.com and we'll take your listing off the site within 3 working days. We have noble intentions and would love to work with partners who understand and appreciate the value we are trying to drive to advertisers and media owners alike.</li>
                        <li>We use cookies on our site. A cookie is a small piece of information stored by a web server on a web browser so it can be later read back from that browser. Cookies are useful for enabling the browser to remember information specific to a given user. We place both permanent and temporary cookies in your computer's hard drive. The cookies do not contain any of your personally identifiable information. These cookies are safe and help you more than they help us by making your browsing experience more fruitful. These are not dangerous and don't harm you or your computer.</li>
                    </ul>
                </div>
            </div>

        </>
    );
}


export default Privacy