import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { IoLocation } from "react-icons/io5";
import Facebook from '../images/facebook.png';
import Instagram from '../images/instagram.png';
import Google from '../images/google.png';
import { FaArrowUp, FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import Logo from '../images/logoImage.png';
// import Logo from '../images/gandhinagarLogo.png'
import Ssl from '../images/ssl.png';
import GST from '../images/gst.png';
import MadeInIndia from '../images/made_in_india.png';
import Paytm from '../images/paytm.png';
import Mastercard from '../images/masterCard.png';
import Mims from '../images/msme.png';
import WhatsApp from '../images/whatsApp.png';
import Visa from '../images/visa.png';
import Gpay from '../images/gpay.png';
import JD from '../images/justdial-seeklogo.png'
const baseurl = process.env.REACT_APP_BASE_URL_FOR_NAVBAR

const Footer = () => {
    const navigate = useNavigate();
    const date = new Date()
    const currentYear = date.getFullYear()
    const [showScrollButton, setShowScrollButton] = useState(false);
    const [trustLogoSrc, setTrustLogoSrc] = useState('');

    useEffect(() => {
        const tlJsHost = (window.location.protocol === "https:") ? "https://secure.trust-provider.com/" : "http://www.trustlogo.com/";
        const script = document.createElement('script');
        script.src = `${tlJsHost}trustlogo/javascript/trustlogo.js`;
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            setTrustLogoSrc("https://www.positivessl.com/images/seals/positivessl_trust_seal_lg_222x54.png");
        }
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > window.innerHeight) {
                setShowScrollButton(true);
            } else {
                setShowScrollButton(false);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleLinkClick = (url) => {
        navigate(url);
        window.scrollTo(0, 0);
    };


    return (
        <footer className="text-center text-lg-start">

            <section className="d-flex justify-content-center justify-content-lg-between p-2 border-bottom main" >
                <div className="me-5 d-none d-lg-block">
                    <span>Get connected with us on social networks:</span>
                </div>

                <div>
                    <a href="https://www.facebook.com/profile.php?id=61556908682608" target="_blank" className="me-4 text-reset">
                        <img src={Facebook} width="25px" />
                    </a>
                    <a href="https://www.instagram.com/ahmedabad_visibility_ventures/" target="_blank" className="me-4 text-reset">
                        <img src={Instagram} width="25px" />
                    </a>
                    <a href="https://www.google.com/" target="_blank" className="me-4 text-reset">
                        <img src={Google} width="25px" />
                    </a>
                    <a href="https://wa.me/+919408170571" target="_blank" className="me-4 text-reset">
                        <img src={WhatsApp} width="26px" />
                    </a>
                    <a href="https://www.justdial.com/Ahmedabad/AHMEDABAD-VISIBILITY-VENTURES-Mithakhali/079PXX79-XX79-240319112850-I5N3_BZDET" target="_blank" className="me-4 text-reset">
                            <img src={JD} width="60px" />
                        </a>
                </div>
            </section>
            <section className="main pt-5">
                <div className="container text-center text-md-start ">
                    <div className="row">
                        <div className="col-md-12 col-lg-3 mb-4">

                            <h6 className="mb-4">
                                <a onClick={() => handleLinkClick(`${baseurl}`)}> <img src={Logo} width="100%" height='auto' alt="Logo" className="footer_main_logo" /></a>
                            </h6>
                            <p style={{ textAlign: 'justify', fontSize: "small" }}>

                                Creating tabloid ads can significantly enhance your business's visibility and attract attention in a crowded marketplace. These attention-grabbing advertisements offer a unique platform to showcase your products or services, captivating audiences with bold headlines, vibrant visuals, and compelling messaging.
                            </p>
                        </div>

                        <div className="col-md-4 col-lg-2 mb-4">
                            <h6 className="text-uppercase fw-bold text-center mb-4">Quick Links</h6>
                            <ul className="list-unstyled">
                                <li className="mb-3 text-center">
                                    <a href={`${baseurl}`} className="text-reset">Home</a>
                                </li>
                                <li className="mb-3 text-center">
                                    <a href={`${baseurl}/?page=aboutUs`} className="text-reset">About Us</a>
                                </li>
                                <li className="mb-3 text-center">
                                    <a href={`${baseurl}/?page=inquiry`} className="text-reset">Contact Us</a>
                                </li>
                                <li className="mb-3 text-center">
                                    <a href={`${baseurl}/?page=services`} className="text-reset">Services</a>
                                </li>

                            </ul>
                        </div>


                        <div className="col-md-4 col-lg-2 mb-4">
                            <h6 className="text-uppercase fw-bold mb-4 text-center">Quick Links</h6>
                            <ul className="list-unstyled">
                                <li className="mb-3 text-center">
                                    <a href={`${baseurl}/?page=how_to_ads_book`} className="text-reset">How to Book Ads</a>
                                </li>
                                <li className="mb-3 text-center">
                                    <a href={`${baseurl}/?page=adsSize`} className="text-reset">Ads Size</a>
                                </li>
                                <li className="mb-3 text-center">
                                    <a href={`${baseurl}/?page=whyAds`} className="text-reset">Why Ads</a>
                                </li>
                                <li className="mb-3 text-center">
                                    <a href={`${baseurl}/?page=advantages`} className="text-reset">Advantages</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-4 col-lg-2 mb-4">
                            <h6 className="text-uppercase fw-bold mb-4 text-center">Quick Links</h6>
                            <ul className="list-unstyled">
                                <li className="mb-3 text-center">
                                    <a href={`${baseurl}/PrivacyPolicy`} target="_blank" className="text-reset">Privacy Policy</a>
                                </li>
                                <li className="mb-3 text-center">
                                    <a href={`${baseurl}/TermAndCondition`} target="_blank" className="text-reset">term & condition</a>
                                </li>
                                <li className="mb-3 text-center">
                                    <a href={`${baseurl}/Refund `} target="_blank" className="text-reset">Refund</a>
                                </li>
                                <li className="mb-3 text-center">
                                    <a href={`${baseurl}`} className="text-reset"> Success Story</a>
                                </li>
                            </ul>
                        </div>

                        <div className="col-md-12 col-lg-3 mb-4">
                            <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                            <p>
                            <IoLocation className="me-2" />

                                Above HDFC Bank, Mithakhadi, Gujarat,Ahmedabad. 380009</p>
                            <p>
                                <MdEmail />
                                <span className="footer_email"> branding@ahmedabadvisibilityventures.com</span>
                            </p>
                            <p><FaPhoneAlt className="me-2" />+91 9408170571</p>

                        </div>
                    </div>
                    <hr />
                    <div className="row d-flex justify-content-center">
                        <div className="col-sm-6">
                        <h6 className="text-center">OUR CERTIFICATE</h6>

                            <hr/>
                           <img src={GST} width="20%" height='auto' alt="gst" className="mx-2" />
                        <img src={Mims} width="20%"  height='auto' alt="mims" className="mx-2" />
                          <img src={MadeInIndia} width="20%" alt="made In Inadia"  height='auto' className="mx-2" />

                        </div>
                        <div className="col-sm-6">
                        <h6 className="text-center">PAYMENT METHODS</h6>
                        <hr/>
                           <img src={Paytm} width="20%"  height='auto' alt="paytm" className="mx-2" />
                           <img src={Mastercard} width="20%"  height='auto' alt="mastercard" className="mx-2" />
                           <img src={Visa} width="20%"  height='auto' alt="visa" className="mx-2" />
                         <img src={Gpay} width="20%"  height='auto' alt="gpay" className="mx-2" />
                        </div>
                    </div>
                </div>
            </section>




            <div className="row d-flex justify-content-center align-items-center copyright p-1">
                <div className="col-sm-12 col-md-4 ">
                    <img src={Ssl} width="30%"  height='auto' alt="ssl-logo"/>

                </div>
                <div className="col-sm-12  col-md-4 text-center">
                    <p>&copy;{currentYear} Ahmedabad Visibility Ventures. All Rights Reserved.</p>
                    {trustLogoSrc && <img src={trustLogoSrc} alt="TrustLogo" width="35%"  height='auto' />}

                </div>
                <div className="col-sm-12 col-md-4 ">
                    <p className="gst_footer m-2 ">GST NO: 24FAGPS7416R2ZU</p>
                </div>
            </div>


            {showScrollButton && (
                <button className="scroll-to-top" onClick={scrollToTop}>
                    <FaArrowUp />
                </button>
            )}
        </footer>

    )
}

export default Footer;
