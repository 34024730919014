import React, { useState, useEffect } from "react";
import axios from "axios";
import SuccessStoryText from "../images/mid_header_img.png";
import UpArrow from "../images/chevron-circle-up-alt.svg";
import backgroundImage from "../images/avv_bg.png";
import { useNavigate } from "react-router-dom"; // Assuming you're using React Router for navigation
import DocumentMeta from "react-document-meta";

const meta = {
  title:
    "Success Stories - Boosting Business Visibility | Ahmedabad Visibility Ventures",
  description:
    "Read inspiring success stories of businesses transformed by Ahmedabad Visibility Ventures. Learn how our innovative advertising strategies, including impactful tabloid ads, have significantly enhanced visibility and growth for various enterprises. Discover how we can help your business succeed.",
};
const SuccessStoryList = () => {
  const baseurl = process.env.REACT_APP_BASE_URL;
  const profileEndPoint = process.env.REACT_APP_PROFILE_LIST;
  const profileImage = process.env.REACT_APP_PROFILE_IMAGE;

  const navigate = useNavigate();

  // States for managing data
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(8);
  const [searchTerm, setSearchTerm] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(true); // Default open on desktop
  const [isMobile, setIsMobile] = useState(false);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
    if (window.innerWidth <= 768) {
      setSidebarOpen(false);
    }
  };
  // Fetch data on initial render
  useEffect(() => {
    handleResize(); // Initial check
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      let response = await axios.get(`${baseurl}/${profileEndPoint}`);
      response.data.data = response.data.data.filter(
        (item) => item.status === true
      );
      console.log(response.data.data);
      setUsers(response.data.data);
      setFilteredUsers(response.data.data); // Initially, show all users

      // Extract unique categories from users data
      const uniqueCategories = [
        ...new Set(response.data.data.map((user) => user.category)),
      ];
      setCategories(uniqueCategories);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  // Handle category click to filter users
  const handleCategoryClick = (category) => {
    if (selectedCategory === category) {
      setSelectedCategory(null); // Collapse if already selected
      setSelectedName(null); // Reset name
      setFilteredUsers(users); // Show all users again
    } else {
      setSelectedCategory(category);
      setSelectedName(null); // Reset name
      const filteredData = users.filter((user) => user.category === category);
      setFilteredUsers(filteredData); // Filter users by selected category
    }
    setCurrentPage(1); // Reset to first page when changing category
  };

  // Handle name click to filter users
  const handleNameClick = (name) => {
    setSelectedName(name);
    const filteredData = users.filter(
      (user) => user.category === selectedCategory && user.name === name
    );
    setFilteredUsers(filteredData); // Filter users by selected name
    setCurrentPage(1); // Reset to first page when selecting name
  };

  // Handle user click to search users by name
  const handleUserClick = (userName) => {
    const filteredData = users.filter((user) => user.name === userName);
    setFilteredUsers(filteredData); // Filter users by selected username
    setCurrentPage(1); // Reset to first page when selecting username
  };

  // Pagination
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Filtering based on search term
  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    const filteredData = users.filter(
      (user) =>
        user.name.toLowerCase().includes(searchTerm) ||
        user.category.toLowerCase().includes(searchTerm) ||
        (user.name && user.name.toLowerCase().includes(searchTerm))
    );
    setFilteredUsers(filteredData);
    setCurrentPage(1); // Reset to first page when searching
  };

  // Reset search term and filters
  const handleReset = () => {
    setSearchTerm("");
    setFilteredUsers(users); // Reset to show all users
    setSelectedCategory(null); // Reset selected category
    setSelectedName(null); // Reset selected name
    setCurrentPage(1); // Reset pagination to first page
  };

  // Navigate to specific user story
  const openSpecificStory = (name, visitorCount) => {
    name = name.replace(/[\sA-Z]/g, function (match) {
      if (match === " ") {
        return "_";
      } else {
        return match.toLowerCase();
      }
    });
    navigate(`/Success_Story/${name}/${visitorCount * 3}`);
  };
  const handlenameClick = (name) => {
    setSelectedName(name);
    const filteredData = users.filter(
      (user) => user.category === selectedCategory && user.name === name
    );
    setFilteredUsers(filteredData); // Filter users by selected name
    setCurrentPage(1); // Reset to first page when selecting name
  };
  const headerStyle = {
    backgroundImage: `url(${backgroundImage})`,
    /* You can add more styles like height, padding, etc. */
  };
  const openSideBar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  return (
    <>
      <DocumentMeta {...meta} />
      {/* <div className="container-fluid d-flex justify-content-center success-story">
                <img src={SuccessStoryText} style={{ width: '40%' }} className="text-center" alt="text-img" />
            </div> */}
      <div
        class="header header-sucstory header-sucstory-list"
        style={headerStyle}
      >
        <div class="header-content header-content-list">
          <img src={SuccessStoryText} alt="Header Image" class="header-image" />
        </div>
      </div>
      <div class="d-flex" id="wrapper">
        <div
          class="suc-story"
          id="sidebar-wrapper"
          style={{ display: sidebarOpen ? "block" : "none" }}
        >
          <div class="close-btn-main">
            {/* <button class="btn btn-link" id="closeSidebar">
                            <i class="fa fa-times"></i>
                        </button> */}
          </div>
          <div class="side-nav-contetn">
            <div>
              {isMobile === true && (
                <button
                  class="btn btn-primary sidebar-cat-toggle"
                  onClick={() => openSideBar()}
                >
                  <i class="fa fa-close"></i>
                </button>
              )}
            </div>
            <div class="sidebar-heading mt-2 mb-4 d-flex justify-content-between align-items-center">
              <span>Categories</span>
            </div>
            <div class="menu">
              {categories.map((category, index) => (
                <div key={index} className="menu-item">
                  <div
                    id="sidebarContent"
                    className="list-group list-group-flush"
                    onClick={() => handleCategoryClick(category)}
                  >
                    <a
                      href="#category1"
                      class="category-list list-group-item list-group-item-action list-group-item-light p-3 dropdown-toggle"
                      data-bs-toggle="collapse"
                      aria-expanded="true"
                    >
                      <span class="category-title mt-3">{category}</span>
                      <img
                        src={UpArrow}
                        class={`downarrow-cat ${
                          selectedCategory === category ? "special-class" : ""
                        }`}
                      />
                    </a>
                  </div>
                  {/* Render subcategories under selected category */}
                  {selectedCategory === category && (
                    <div>
                      {users
                        .filter(
                          (user) => user.category === category && user.name
                        )
                        .map((user, subIndex) => (
                          <a
                            href="javascript:void(0)"
                            onClick={() => handlenameClick(user.name)}
                            className="category-list-detail-title list-group-item list-group-item-action list-group-item-light ps-5"
                            data-content="content1a"
                          >
                            {user.name}
                          </a>
                        ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div id="page-content-wrapper">
          <nav class="navbar navbar-expand-lg d-flex justify-content-between align-items-center sucess_storynav">
            <div>
              {isMobile === true && (
                <button
                  class="btn btn-primary sidebar-cat-toggle"
                  onClick={() => openSideBar()}
                >
                  <i class="fa fa-bars"></i>
                </button>
              )}
            </div>
          </nav>
          <div className="container-fluid main-content-section p-0">
            <div className="input-group mb-5 justify-content-end">
              <div className="custom-reset-css">
                <button
                  className="btn btn-outline-secondary cat-sub-text"
                  type="button"
                  onClick={handleReset}
                >
                  Reset Filter
                </button>
              </div>
              <div className="form-group has-search search-bar-main mr-2">
                <span className="fa fa-search form-control-feedback search-ic"></span>
                <input
                  type="text"
                  className="form-control search-bar-input"
                  placeholder="Search Name"
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>
            </div>
            <div className="p-4">
              {/* Dynamic content based on currentPage */}
              <div
                id={`content-${currentPage}`}
                className="content-section"
                style={{ display: "block" }}
              >
                <div className="category-content-main">
                  <div className="row">
                    {/* Example of dynamic cards */}
                    {currentUsers.map((user, index) => (
                      <div
                        key={index}
                        className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12 col-12 d-flex align-items-stretch p-4 cat-mn-crd pb-1"
                      >
                        <div
                          className="card"
                          onClick={() =>
                            openSpecificStory(user.name, user.visitorCount)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={`${profileImage}profile_images/${user.profileImage}`}
                            className="card-img-top cat-img"
                            alt="Card Image"
                          />
                          <div className="card-body d-flex flex-column">
                            <h5 className="card-title cat-sub-title text-center">
                              {user.name}
                            </h5>
                            <p className="card-text cat-sub-text text-center">
                              {user.designation}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}

                    <div className="pagination">
                      <button
                        className="arrow"
                        id="prevPage"
                        onClick={() => paginate(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        <span className="nav-text">PREV</span>
                      </button>
                      <div className="pages">
                        {/* Assuming fixed number of pages for demo */}
                        {Array.from(
                          Array(
                            Math.ceil(filteredUsers.length / usersPerPage)
                          ).keys()
                        ).map((index) => (
                          <div
                            key={index}
                            className={`page-number ${
                              currentPage === index + 1 ? "active" : ""
                            }`}
                            onClick={() => paginate(index + 1)}
                          >
                            {index + 1}
                          </div>
                        ))}
                      </div>
                      <button
                        className="arrow"
                        id="nextPage"
                        onClick={() => paginate(currentPage + 1)}
                        disabled={
                          currentPage ===
                          Math.ceil(filteredUsers.length / usersPerPage)
                        }
                      >
                        <span className="nav-text">NEXT</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessStoryList;
