import { useEffect, useState } from "react";
import { Link, useLocation } from 'react-router-dom'

const SuccessPage = () => {
    const location = useLocation(); 
    const [orderId, setOrderId] = useState(null);

    useEffect(() => {
        if (location.state && location.state.orderId) {
            setOrderId(location.state.orderId);
        }
    }, [location.state]);

    return (
        <div className="container-fluid thankyou-page">
            <div className="thankyou_header m-4">
                <h1>Thank You!</h1>
            </div>

            <div className='row d-flex justify-content-center thankyou-row'>
                <div className='col-md-12 p-4'>
                    <h2 className='thankyou_page_header2 text-center'>
                        <strong>Your Ad is Booked</strong>
                    </h2>
                    {orderId && (
                        <div className="text-center mt-4">
                            <h4 style={{ color: '#0E53A9    ', textAlign: 'center' }}>Your order_id ID: {orderId}</h4>
                        </div>
                    )}
                    {/* <h1 style={{ color: '#0E53A9', textAlign: 'center' }}>Your Order ID</h1> */}
                    <p className='text-center mt-4'>Please Check Your Registered Mail Id for Invoice.</p>
                    <div className="_footer text-center mt-4">
                        <Link className="navbar_btn" style={{ textDecoration: 'none' }} to="/">Back to Home</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SuccessPage;
