import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import header from '../../images/logoImage.png';
import Scan from '../../images/scan.jpg';
import axios from 'axios';
import Swal from 'sweetalert2';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
// import Spinner from 'react-bootstrap/Spinner';
import Loader from '../../images/loading.png'

const baseurl = process.env.REACT_APP_BASE_URL;
const adsEndpoint = process.env.REACT_APP_FIND_ADS_ENDPOINT;
const bookedAdsEndpoint = process.env.REACT_APP_BOOKED_ADS_ENDPOINT;
const clientEndpoint = process.env.REACT_APP_CREATECLIENT_ENDPOINT;
const paymentEndpoint = process.env.REACT_APP_ADS_PAYMENT_ENDPOINT;
const payment_key = process.env.REACT_APP_KEY_ID;
const createPayment_Endpoint = process.env.REACT_APP_CREATE_PAYMENT_ENDPOINT;
const paymentStatus_Endpoint = process.env.REACT_APP_UPDATE_PAYMENT_STATUS_ENDPOINT;
const emailEndpoint = process.env.REACT_APP_ADS_EMAIL_ENDPOINT;
// const imageurl = process.env.IMAGE_URL
const imageurl = 'https://qgbimages.s3.ap-south-1.amazonaws.com/AdsImage'

const Page3 = () => {
    const navigate = useNavigate()
    const Location = useLocation();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    const [orderId, setOrderId] = useState('');
    const selectedDate = Location.state && Location.state.selectedDate;
    const slotId = Location.state && Location.state.slotId;
    const formattedDate = selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : null;
    const formattedDateForPage = selectedDate ? moment(selectedDate).format('DD-MM-YYYY') : null;
    if (formattedDate == null) {
        navigate('/Slot')
    }

    const [show, setShow] = useState(false);
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [location, setLocation] = useState('')
    const [GST_No, setGST_No] = useState('')
    const [paymentMode, setPaymentMode] = useState('online');

    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [locationError, setLocationError] = useState('');


    const [data, setData] = useState([]);
    const [selectedAdId, setSelectedAdId] = useState(null);

    const handleKeyDown = (e) => {
        if (!((e.keyCode > 95 && e.keyCode < 106) || // Numpad keys
            (e.keyCode > 47 && e.keyCode < 58) ||  // Number keys
            e.keyCode === 8 || e.keyCode === 9 ||  // Backspace and Tab
            e.keyCode === 37 || e.keyCode === 39 || // Left and Right arrow keys
            e.keyCode === 46                         // Delete key
        )) {
            e.preventDefault();
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {

                const data = {
                    page_id: "65eeda29e1c47776ec8ce287",
                    slot_Date: formattedDate
                }
                const url = `${baseurl}/${adsEndpoint}`;
                const response = await axios.post(url, data)
                setData(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handlePayment = async (amount, booked_id) => {
        setLoading(true)
        const taxPercentage = 0.05;
        const taxAmount = amount * taxPercentage;
        const TotalAmount = taxAmount + amount
        const amountNumber = parseFloat(TotalAmount);
        try {

            const option = {
                amount: amountNumber,
                booked_id: booked_id || ''
            };
            const response = await axios.post(`${baseurl}/${paymentEndpoint}`, option);
            setOrderId(response.data.data.id);
            const orderId = response.data.data.id
            const options = {
                key: payment_key,
                amount: amountNumber * 100,
                currency: 'INR',
                name: name,
                description: 'Payment',
                order_id: orderId,
                handler: async function (response) {
                    if (response.razorpay_payment_id) {
                        try {
                            console.log(orderId)
                            const createPaymentResponse = await axios.post(`${baseurl}/${createPayment_Endpoint}`, {
                                razorpay_payment_id: response.razorpay_payment_id,
                                orderId: orderId,
                                booked_id: booked_id,
                                slot_id: slotId,
                                amount: amount,
                                cityId: 1
                            });

                            const updatePaymentResponse = await axios.put(`${baseurl}/${paymentStatus_Endpoint}`, {
                                orderId: orderId,
                                booked_id: booked_id,
                                cityId: 1
                            });

                            const sendEmail = await axios.post(`${baseurl}/${emailEndpoint}`, { booked_id: booked_id, cityId: 1 });
                            if (sendEmail.data.status == true) {
                                navigate('/Success', { state: { orderId: sendEmail.data.data.orderId } });
                            }
                        } catch (error) {
                            console.error('Error updating payment status:', error);
                        }
                    }
                },
                prefill: {
                    name: name,
                    email: email,
                    contact: mobile
                },
                notes: {
                    address: location,
                    price: amountNumber * 100
                },
                theme: {
                    color: '#F37254'
                }
            };

            const rzp = new window.Razorpay(options);
            rzp.on('payment.failed', function (response) {
                // alert("paymnet fail", response.error.code);
                // alert(response.error.description);
                navigate('/Fail');
                // alert(response.error.source);
                // alert(response.error.step);
                // alert(response.error.reason);
                // alert(response.error.metadata.order_id);
                // alert(response.error.metadata.payment_id);
            })
            rzp.open();

        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };
    // const handlePayment = async (TotalAmount, booked_id) => {

    //     const amountNumber = parseFloat(1);
    //     try {

    //         const option = {
    //             amount: amountNumber,
    //             booked_id: booked_id || ''
    //         };
    //         const response = await axios.post(`${baseurl}/${paymentEndpoint}`, option);
    //         setOrderId(response.data.data.orderId);


    //         const options = {
    //             key: payment_key,
    //             amount: amountNumber * 100,
    //             currency: 'INR',
    //             name: name,
    //             description: 'Test Payment',
    //             order_id: orderId,
    //             handler: async function (response) {
    //                 if (response.razorpay_payment_id) {
    //                     try {
    //                         const updatePaymentResponse = await axios.put(`${baseurl}/${paymentStatus_Endpoint}`, {
    //                             orderId: orderId,
    //                             booked_id: booked_id
    //                         });
    //                        const sendEmail = await axios.post(`${baseurl}/${emailEndpoint}`,{booked_id:booked_id});
    //                        if(sendEmail.data.status == true){
    //                         navigate('/Success', { state: { orderId: sendEmail.data.data.orderId } });
    //                        }
    //                     } catch (error) {
    //                         console.error('Error updating payment status:', error);
    //                     }
    //                 }
    //             },
    //             prefill: {
    //                 name: name,
    //                 email: email,
    //                 contact: mobile
    //             },
    //             notes: {
    //                 address: location,
    //                 price: amountNumber * 100
    //             },
    //             theme: {
    //                 color: '#F37254'
    //             }
    //         };

    //         const rzp = new window.Razorpay(options);
    //         rzp.open();
    //     } catch (error) {
    //         console.error('Error:', error);
    //     }
    // };
    const handleBookAds = async (adsId) => {

        try {
            setSelectedAdId(adsId);
            setShow(true);
        } catch (error) {
            console.error('Error booking ads:', error);
        }
    };
    const handleClose = () => setShow(false);
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!name) {
            setNameError('Name is required');
            return;
        } else {
            setNameError('');
        }

        // Validation for email
        const emailPattern = /\S+@\S+\.\S+/;
        if (!email) {
            setEmailError('Email is required');
            return;
        } else if (!emailPattern.test(email)) {
            setEmailError('Invalid email format');
            return;
        } else {
            setEmailError('');
        }

        // Validation for mobile
        const mobilePattern = /^\d{10}$/;
        if (!mobile) {
            setMobileError('Mobile is required');
            return;
        } else if (!mobilePattern.test(mobile)) {
            setMobileError('Invalid mobile number format');
            return;
        } else {
            setMobileError('');
        }
        if (!location) {
            setLocationError('Location is required');
            return;
        } else {
            setLocationError('');
        }
        try {
            const clientData = {
                name: name,
                email: email,
                mobile: mobile,
                location: location,
                GST_No: GST_No,
                payment_type: 'online',
                cityId: 1
            }
            const clientresponse = await axios.post(`${baseurl}/${clientEndpoint}`, clientData);
            const clientId = clientresponse.data.data._id
            const data = {
                ads_id: selectedAdId,
                page_id: "65eeda29e1c47776ec8ce287",
                slot_id: slotId,
                client_id: clientId,
                slot_Date: formattedDate,
                cityId: 1
            }
            const response = await axios.post(`${baseurl}/${bookedAdsEndpoint}`, data);
            if (response.status === 200) {
                const amount = response.data.data.ads_id.price
                const booked_id = response.data.data._id
                setShow(false);
                if (paymentMode === "online") {

                    handlePayment(amount, booked_id)
                } else {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Ads booking successful',
                    });
                }
            } else {
                console.error('Error booking ads:', response.data.message);
            }
        } catch (error) {
            console.error('Error booking ads:', error);
        }
    };


    const generateAdsComponents = () => {
        return data.map((ad, index) => (
            <>
                {ad.image ? (
                    <>
                        {/* <div key={index}> */}
                        <img src={`${imageurl}/${ad.image}`} width="100%" alt="Ads" className={`col-5 text-center m-1 ads${index + 1}_For_page3 adsImage`} />
                        {/* </div> */}
                    </>
                ) : (
                    <>
                   <div key={index} className={`col-5 text-center m-1 ads${index + 1}_For_page3 ${ad.Is_booked ? "IsBooked" : "notBooked"}`}>
                            {ad.image ? (
                                <></>
                            ) : (
                                <>
                                    <p>PageSize:- {ad.pageSize}</p>
                                    <p>Price:- {ad.price}</p>
                                    {ad.Is_booked && <p><b>This ad is booked.</b></p>}
                                    {!ad.Is_booked && <button className="allpage_book_btn" onClick={() => handleBookAds(ad._id)}>Book Ads</button>}
                                </>
                            )}
                        </div>
                    </>
                )}
            </>

        ));
    };
    return (
        <div className="container" style={{ paddingTop: '11rem', paddingBottom: '8rem' }}>
           <h2 className="headings">Selected Date:{formattedDateForPage}</h2>
            <div className="row d-flex justify-content-center">
                <div className="col-md-10 col-sm-10 col-lg-6 page3">
                    <div className="row d-flex justify-content-center p-1">
                        <div className="row d-flex justify-content-center align-items-center p-1" style={{ background: '#f8f9fb', borderRadius: '10px' }}>
                            <div className="col-4 bg-light pageDate"> <h5>{formattedDateForPage}</h5></div>
                            <div className="col-6 bg-light">  <img src={header} className="logo-for-page" /></div>
                            <div className="col-2 bg-light pageScan" >  <img src={Scan} className="logo-for-page" /></div>
                        </div>
                        {generateAdsComponents()}
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>add Your details:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="clientName">Name:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="clientName"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                            {nameError && <p className="text-danger">{nameError}</p>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="clientEmail">Email:</label>
                            <input
                                type="email"
                                className="form-control"
                                id="clientEmail"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            {emailError && <p className="text-danger">{emailError}</p>}

                        </div>
                        <div className="form-group">
                            <label htmlFor="clientLocation">Location:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="clientLocation"
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                                required
                            />
                            {locationError && <p className="text-danger">{locationError}</p>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="clientMobile">Mobile:</label>
                            <input
                                type="tel"
                                className="form-control"
                                id="clientMobile"
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                                onKeyDown={handleKeyDown}
                                required
                            />
                            {mobileError && <p className="text-danger">{mobileError}</p>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="clientMobile">GST No:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="clientgdtNo"
                                value={GST_No}
                                onChange={(e) => setGST_No(e.target.value)}
                            />
                        </div>
                        {/* <div className="form-group">
                            <label>Mode of Payment:</label><br />
                            <input
                                type="radio"
                                id="online"
                                name="paymentMode"
                                value="online"
                                checked={paymentMode === "online"}
                                onChange={() => setPaymentMode("online")}
                            />
                            <label htmlFor="online">Online</label><br />
                            <input
                                type="radio"
                                id="offline"
                                name="paymentMode"
                                value="offline"
                                checked={paymentMode === "offline"}
                                onChange={() => setPaymentMode("offline")}
                            />
                            <label htmlFor="offline">Offline</label>
                        </div> */}
                    </form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" className="client-btn" onClick={handleSubmit}>
                        paynow
                    </Button>
                </Modal.Footer>
            </Modal>
            {loading && <img src={Loader} alt="Loading..." className='loading' />}

        </div>

    );
}

export default Page3;

