import React from "react";
import Person from '../images/newMan.png'

const Section5 = () => {
    return (
        <div className="container mt-5 section5" id="whyAds">
            <div className="section5_content">
                <h1 className="headings pt-5 mb-5"> NEED OF NEWSPAPER TABLOID ADVERTISEMENT </h1>
                <div className="row">
                    <p>In today's fast-paced digital world, the need for newspaper insertion advertising stands out as a powerful,
                        tactile strategy that bridges the gap between traditional and innovative marketing methods.
                        Newspaper inserts provide a unique opportunity to capture the attention of a diverse audience in a way that digital media often can't—through the tangible, engaging experience of physically holding an advertisement in one's hands. This approach not only enhances visibility but also boosts the credibility of your brand, leveraging the established trust and habitual readership associated with newspapers.</p>
                </div>
                <div className="row d-flex justify-content-center mt-5">
                   
                    <div className="col-sm-12 col-md-12 col-lg-6 ">
                        <p>The necessity of newspaper insertion advertising lies in its ability to deliver targeted messages with precision. Unlike the scattergun approach of many digital ads, inserts in newspapers can be strategically placed in editions or sections most relevant to your target demographic, ensuring your message reaches the right audience at the right time. This method is especially effective in penetrating local markets, where community ties to print media remain strong.</p>
                        <p>Moreover, newspaper insertion advertisements are incredibly versatile, supporting a wide range of formats from simple flyers to intricate brochures, allowing for creative and informative campaigns that engage potential customers. This flexibility ensures that whether your goal is to announce a new product, promote a special offer, or increase brand awareness, there's a newspaper insert format that can meet your needs.</p>
                        <p>In addition to their direct impact, newspaper inserts also benefit from a longer lifespan compared to digital ads. They remain in circulation as long as the newspaper does, often viewed by multiple members of a household or passed along to friends and colleagues, extending their reach further than initially anticipated.</p>
                    <p>Given these advantages, the need for newspaper insertion advertising is clear. It offers a tangible, credible, and targeted medium that complements digital strategies, ensuring a comprehensive approach to marketing that can significantly enhance brand visibility, engagement, and ultimately, the success of your campaigns.</p>

                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6 mb-5 d-flex justify-content-center ">
                        <img src={Person} width="70%" alt="person" className="rotate-image" />
                    </div>
                </div>
               
            </div>
        </div>
    )
}

export default Section5