import React from "react";

const Whypaperinsertion = () => {
    return (
        <div className="container mt-5">
            <h1 className="headings mb-4 text-center">Why Paper Insertions?</h1>
            <div className="row justify-content-center text-center">
                <div className="col-sm-6 col-md-4 col-lg-4 mt-2">
                    <div className="whyPaper_insertion1 h-100">
                        <h5 style={{ fontWeight: '600' ,marginBottom:'15px'}}>Direct Impact:</h5>
                        <p>Captivate your audience with tangible ads they can't ignore.</p>
                    </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mt-2">
                    <div className="whyPaper_insertion2 h-100">
                        <h5 style={{ fontWeight: '600',marginBottom:'15px' }}>Customized Reach: </h5>
                        <p>Target specific demographics and locations for precise marketing.</p>
                    </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mt-2">
                    <div className="whyPaper_insertion3 h-100">
                        <h5 style={{ fontWeight: '600',marginBottom:'15px' }}>Cost-Effective:</h5>
                        <p>Enjoy high ROI with affordable, yet impactful advertisement options.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Whypaperinsertion;
