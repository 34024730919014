import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import SuccessStoryText from "../images/sm_header_img.png";
// import Aditi from '../images/aditi.png';
import Success1 from "../images/success1.png";
import Success2 from "../images/success2.png";
import Pratik from "../images/pratik.png";
import Rightads from "../images/rightAd.png";
import Leftads from "../images/leftAd.png";
import { AiOutlineTranslation } from "react-icons/ai";
import Lastimg from "../images/successLastads.png";
import backgroundImage from "../images/sm_bg_header.png";
import contentBgImage from "../images/home_bg5.png";
import "../css/style.css";
import { Adsense } from "@ctrl/react-adsense";

const SuccessStory = () => {
  const [user, setUser] = useState({});
  const baseurl = process.env.REACT_APP_BASE_URL;
  const profileEndPoint = process.env.REACT_APP_FETCH_PROFILE;
  const profileImage = process.env.REACT_APP_PROFILE_IMAGE;
  const visitorCoutEndPoint = process.env.REACT_APP_PROFILE_VIEW_ENDPOINT;

  const { name, visitorcnt } = useParams();
  useEffect(() => {
    // Fetch categories and users data
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      let ipAddress = await axios.get(`https://api.ipify.org/?format=json`);
      const response = await axios.post(`${baseurl}/${profileEndPoint}`, {
        name: name,
      });
      setUser(response.data.data);
      await axios.post(`${baseurl}/${visitorCoutEndPoint}`, {
        name: name,
        ipAddress: ipAddress.data.ip,
      });
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };
  const createMarkup = (html) => ({ __html: html });
  const headerStyle = {
    backgroundImage: `url(${backgroundImage})`,
    /* You can add more styles like height, padding, etc. */
  };
  const contentBgStyle = {
    backgroundImage: `url(${contentBgImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover", // Optional: Adjust size as needed
    backgroundPosition: "center", // Optional: Adjust position as needed
  };
  return (
    <>
      <div
        class="header header-sucstory header-sucstory-profile"
        style={headerStyle}
      >
        <div class="header-content">
          <img
            src={SuccessStoryText}
            alt="Header Image"
            class="header-image"
            style={{ marginTop: "85px" }}
          />
        </div>
      </div>
      <div className="container-fluid">
        <div className="row p-0">
          <img
            src={`${profileImage}/banner_images/${user.bannerImage}`}
            className="img-fluid text-center p-0"
            style={{ width: "100%", maxHeight: "725px", objectFit: "cover" }} // Responsive width, fixed height
            alt="profile-img"
          />
        </div>
      </div>
      <div className="container-fluid">
        <div className="row" style={contentBgStyle}>
          <div className="col-lg-2">
            {/* <img src={Leftads} width="100%" className="text-center"alt="ads1-img"/> */}
          </div>
          <div className="col-lg-8" style={{ color: "#fff" }}>
            <button className="btn navbar_btn mt-3 mb-3">
              <AiOutlineTranslation />
              ગુજરાતી
            </button>
            {user.content
              ? // Split content by <br>, \n, or newline characters and render accordingly
                user.content.split(/<br>|\\n|\n/).map((segment, index) =>
                  // Check if segment is a line break or newline character
                  segment.trim().length === 0 ? (
                    ""
                  ) : (
                    // Render paragraphs for non-empty segments
                    <p
                      key={index}
                      dangerouslySetInnerHTML={createMarkup(segment)}
                    />
                  )
                )
              : ""}
          </div>
          <div className="col-lg-2">
            {/* <img src={Rightads} width="100%" className="text-center" alt="ads-img"/> */}
          </div>
          <div className="col-lg-12">
            <p
              style={{
                float: "right",
                color: "#fff",
                fontSize: "24px",
                paddingRight: "30px",
              }}
            >
              Visitor : {visitorcnt}
            </p>
          </div>
        </div>
        <div className="row p-0">
          <img
            src={profileImage + "ads_images/" + user.adsImage}
            className="img-fluid text-center p-0"
            style={{ width: "100%", maxHeight: "350px", objectFit: "cover" }} // Responsive width, fixed height
            alt="profile-img"
          />
        </div>
        <Adsense client="ca-pub-8107851210668826" slot="7259870550"/>
      </div>
    </>
  );
};

export default SuccessStory;
