import React from "react";

const Refund = () => {
    return (
        <>
         <div className="container-fluid privacy_Image" >
               <h1 className="pt-5">Policy and Procedures</h1>
            </div>

            <div className="container mt-5 mb-5">
                <h4 className="page_h4">Ahmedabad Visibility Ventures Cancellation Policy and Procedures</h4>

                <ol className="ol1">
                    <li className="li2">There will be an offline cancellation process where in user needs to send a written communication to customer care team and make a request for ad cancellation on same day which place ads online or offline. There has been no any refund and cancellation done if any false proof and misguide information.</li>
                    <li className="li2">For assured cancellation, request should be made On same day from date of BOOKING. In case of next day Of booking, request should be sent within 11:30am.</li>
                </ol>

                <p className="p3">Cancellation Request raised before 48 hours of publishing.</p>

                <ol className="ol1">
                    <li className="li2">Advertisement will be cancelled, deadline permitting.</li>
                    <li className="li2">In case received beyond deadline, then no cancellation will be done, however an effort shall be made for stopping it from publishing.</li>
                </ol>

                <p className="p3">Advertisement missed by a Tabloid ads</p>

                <ol className="ol1">
                    <li className="li2">Full amount refunded on request or;</li>
                    <li className="li2">Rescheduling is allowed</li>
                </ol>

                <p className="p3">Request for rescheduling/ Change of advertisement text/adding more publication</p>

                <ol className="ol1">
                    <li className="li2">Allowed if not scheduled already</li>
                    <li className="li2">No Charges apply</li>
                    <li className="li2">Fresh dates will depend upon availability</li>
                    <li className="li4">For extra amount online invoice will be sent. User will have to pay the extra amount basis the invoice</li>
                </ol>

                <h4 className="page_h4">Conduct</h4>

                <p className="p3">The Service(s) offered by <span className="s1">Ahmedabad Visibility Ventures</span> may be used only for lawful purposes. Posting or storage of material or conduct in violation of any applicable local, state, Central or foreign law or regulation is prohibited. This includes without limitation any unauthorized use of material protected by patent, copyright, trademark or other intellectual property right, material that is obscene, defamatory or libelous, constitutes an illegal threat, or violates rights of privacy or publicity, or violates export control laws. The user may use the information on our site only to the extent necessary to facilitate <span className="s1">Ahmedabad Visibility Ventures</span> related transactions.</p>

                <p className="p5">Any content and/or comment/information uploaded by User/You on the Site which is non-compliant with The Information Technology Act, 2000, rules and regulations, guidelines made thereunder, user agreement and privacy policy, as amended/re-enacted from time to time, for access or usage of Company&rsquo;s Services/Site/computer resource, the Company has the right to immediately terminate the access or usage rights of the user to the said Services and Site and remove/disable all information including the non-compliant information. furthermore, the Company shall have the right to take recourse to such remedies as would be available to the Company under the applicable laws.</p>

                <h4 className="page_h4">Applicable Taxes and Costs</h4>

                <p className="p3">User understands and agrees that the User is responsible for all applicable taxes and for all costs that are incurred in using the <span className="s1">Ahmedabad Visibility Ventures</span> service. We may also, in our sole discretion, add or delete fees associated with the Service.</p>
            </div>
        </>
    );
}

export default Refund;
