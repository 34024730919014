import React, { useState, useEffect } from "react";
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import Logo from '../images/logoImage.png';
// import Logo from '../images/gandhinagarLogo.png'
import { useLocation, useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import Swal from 'sweetalert2';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const baseurl = process.env.REACT_APP_BASE_URL_FOR_NAVBAR;
const mainurl = process.env.REACT_APP_BASE_URL
const paymentEndpoint = process.env.REACT_APP_SUCCESS_STORY_PAYMENT_ENDPOINT;
const updateEndpoint = process.env.REACT_APP_UPDATE_STATUS_ENDPOINT
const bookingEndpoint = process.env.REACT_APP_PRE_BOOKING_ENDPOINT;
const sendmailEndpoint = process.env.REACT_APP_SUCCESS__STORY_SEND_MAIL_ENDPOINT
const payment_key = process.env.REACT_APP_KEY_ID;

const Header = () => {

  const [show, setShow] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [location, setLocation] = useState('');
  const [GST_No, setGST_No] = useState('');
  const [amount, setAmount] = useState(15000);

  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [mobileError, setMobileError] = useState('');
  const [locationError, setLocationError] = useState('');

  const handleClose = () => setShow(false);
  const handleOpen = () => setShow(true);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);
  const handlePayment = async (TotalAmount, _id) => {
    const amountNumber = parseFloat(TotalAmount);
    try {

      const option = {
        amount: amountNumber,
      };
      const response = await axios.post(`${mainurl}/${paymentEndpoint}`, option);
      const orderId = response.data.data.id
      const options = {
        key: payment_key,
        amount: amountNumber * 100,
        currency: 'INR',
        name: name,
        description: 'Test Payment',
        order_id: orderId,
        handler: async function (response) {
          if (response.razorpay_payment_id) {
            const updatePayment = await axios.put(`${mainurl}/${updateEndpoint}`, {
              _id: _id,
              orderId: orderId,
              cityId:1
            });
            const sendEmail = await axios.post(`${mainurl}/${sendmailEndpoint}`, { _id: _id });
            if (sendEmail.data.status == true) {
              Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'success story book successful',
              });
            }

          }
        },
        prefill: {
          name: name,
          email: email,
          contact: mobile
        },
        notes: {
          address: location,
          price: amountNumber * 100
        },
        theme: {
          color: '#F37254'
        }
      };

      const rzp = new window.Razorpay(options);
      rzp.on('payment.success', function (response) {
        console.log(response)
      })

      rzp.open();

    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name) {
      setNameError('Name is required');
      return;
    } else {
      setNameError('');
    }

    // Validation for email
    const emailPattern = /\S+@\S+\.\S+/;
    if (!email) {
      setEmailError('Email is required');
      return;
    } else if (!emailPattern.test(email)) {
      setEmailError('Invalid email format');
      return;
    } else {
      setEmailError('');
    }

    // Validation for mobile
    const mobilePattern = /^\d{10}$/;
    if (!mobile) {
      setMobileError('Mobile is required');
      return;
    } else if (!mobilePattern.test(mobile)) {
      setMobileError('Invalid mobile number format');
      return;
    } else {
      setMobileError('');
    }
    if (!location) {
      setLocationError('Location is required');
      return;
    } else {
      setLocationError('');
    }
    const clientData = {
      name: name,
      email: email,
      mobile: mobile,
      location: location,
      GST_No: GST_No,
      amount:amount,
      cityId:1
    }
    const response = await axios.post(`${mainurl}/${bookingEndpoint}`, clientData);
    if (response.status === 200) {
      const amount = response.data.data.amount
      const _id = response.data.data._id
      setShow(false);
      const taxPercentage = 0.05;
      const taxAmount = amount * taxPercentage;
      const TotalAmount = taxAmount + amount
      handlePayment(TotalAmount, _id);
    } else {
      console.error('Error booking ads:', response.data.message);
    }
    try {
    } catch (error) {
      console.error('Error booking ads:', error);
    }
  };
  return (
    <>
      <Navbar collapseOnSelect expand="lg" className="back-image">
        <Container fluid>
          <Navbar.Brand href="/">
            <img src={Logo} width="100%" height="100" alt="Logo" className="logo-img" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto ms-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href={`${baseurl}/?page=aboutUs`}>About us</Nav.Link>
              <Nav.Link href={`${baseurl}/?page=Services`}>Services</Nav.Link>
              <NavDropdown title="Ads Details" id="basic-nav-dropdown">
                <NavDropdown.Item href={`${baseurl}/?page=how_to_ads_book`}>
                  How to book Ads
                </NavDropdown.Item>
                <NavDropdown.Item href={`${baseurl}/?page=adsSize`}>Ads Size</NavDropdown.Item>
                <NavDropdown.Item href={`${baseurl}/?page=whyAds`}>
                  Why Ads
                </NavDropdown.Item>
                <NavDropdown.Item href={`${baseurl}/?page=advantages`}>
                  Advantages
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/success_story">Success Story</Nav.Link>

              <Nav.Link href={`${baseurl}/?page=inquiry`}> Contact Us</Nav.Link>
            </Nav>
            <Nav>
              <Link><button className="btn pre_booking_btn mx-1" onClick={handleOpen}>Pre-Booking For Success Story</button></Link>

              <Link to="/Slot"><button className="navbar_btn">BOOK ADS</button></Link>
              {/* <Nav.Link href="#deets"><button className="btn btn-primary booked-btn">Booked Ads</button></Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>add Your details:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="clientName">Name:</label>
              <input
                type="text"
                className="form-control"
                id="clientName"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              {nameError && <p className="text-danger">{nameError}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="clientEmail">Email:</label>
              <input
                type="email"
                className="form-control"
                id="clientEmail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              {emailError && <p className="text-danger">{emailError}</p>}

            </div>
            <div className="form-group">
              <label htmlFor="clientLocation">Location:</label>
              <input
                type="text"
                className="form-control"
                id="clientLocation"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                required
              />
              {locationError && <p className="text-danger">{locationError}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="clientMobile">Mobile:</label>
              <input
                type="tel"
                className="form-control"
                id="clientMobile"
                value={mobile}
                // onChange={(e) => setMobile(e.target.value)}
                // onKeyDown={handleKeyDown}
                // value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                required
              />
              {mobileError && <p className="text-danger">{mobileError}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="clientMobile">GST No:</label>
              <input
                type="text"
                className="form-control"
                id="clientgdtNo"
                value={GST_No}
                onChange={(e) => setGST_No(e.target.value)}
              />
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" className="client-btn" onClick={handleSubmit}>
            paynow
          </Button>


        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Header;
