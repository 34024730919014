import { useEffect, useState, useRef } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';

const FailPage = () => {
    const [transactionId, setTransactionId] = useState('');
    const isMounted = useRef(true)
    const baseurl = process.env.REACT_APP_BASE_URL;
    const createpaymentEndpoint = process.env.REACT_APP_CREATE_PAYMENT_ENDPOINT;
    const paymentStatus_Endpoint = process.env.REACT_APP_UPDATE_PAYMENT_STATUS_ENDPOINT;
    const emailEndpoint = process.env.REACT_APP_ADS_EMAIL_ENDPOINT;
    const paymentstatusEndpoint = process.env.REACT_APP_CHECK_PAYMENT_ENDPOINT;



    const handlePaymentAfterRedirect = async () => {
        try {
            const storedResponseString = localStorage.getItem('paymentResponse');

            if (storedResponseString) {
                const storedResponseData = JSON.parse(storedResponseString);
                const option = {
                    merchantTransactionId: storedResponseData.merchantTransactionId,
                    amount: storedResponseData.amount,
                    totalAmount: storedResponseData.totalAmount,
                    taxAmount: storedResponseData.taxAmount,
                    booked_id: storedResponseData.booked_id,
                };

                const response = await axios.post(`${baseurl}/${createpaymentEndpoint}`, option);
                if (response.data.status == true) {
                    const transactionId = response.data.data.merchantTransactionId;
                    setTransactionId(transactionId);
                    try {
                        const updatePaymentResponse = await axios.put(`${baseurl}/${paymentStatus_Endpoint}`, {
                            merchantTransactionId: response.data.data.merchantTransactionId,
                            booked_id: response.data.data.booked_id
                        });
                        const sendEmail = await axios.post(`${baseurl}/${emailEndpoint}`, { booked_id: response.data.data.booked_id });
                        if (sendEmail.data.status == true) {
                            console.log('mailsend successful')
                        }
                    } catch (error) {
                        console.error('Error updating payment status:', error);
                    }
                }
            } else {
                console.log('No payment response data found in localStorage');
            }
        } catch (error) {
            console.error('Error handling payment after redirect:', error);
        }
    };
    useEffect(() => {
        if (isMounted.current) {
            handlePaymentAfterRedirect()
            isMounted.current = false
        }
    }, []);

    return (
        <div className="container-fluid thankyou-page">
            <div className="thankyou_header m-4">
                <h1 style={{ color: 'red' }}>Sorry !!!</h1>
            </div>

            <div className='row d-flex justify-content-center thankyou-row'>
                <div className='col-md-12 p-4'>
                    <h2 className='thankyou_page_header2 text-center'>
                        <strong>Your Ad is not Booked</strong>
                    </h2>
                    <div>
                        <h4 style={{ color: '#0E53A9', textAlign: 'center' }}>your payment request is failed!</h4>
                    </div>

                    {/* <h1 style={{ color: '#0E53A9', textAlign: 'center' }}>Your Order ID</h1> */}
                    <div className="_footer text-center mt-4">
                        <Link className="navbar_btn text-uppercase" style={{ textDecoration: 'none' }} to="/">Try again!</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FailPage;
